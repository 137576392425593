
$(function() {

  $(".search input").on("keyup", function() {
    var value = $(this).val().toLowerCase();
    $(this).parent().parent().find('table.index_table tbody tr:not([class^="flash"])').filter(function() {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
    });
  });

  $(".search input").on("keyup", function() {
    var value = $(this).val().toLowerCase();
    $('fieldset.has_many_fields .filterable').filter(function() {
      $(this).parent().parent().toggle($(this).val().toLowerCase().indexOf(value) > -1)
    });
  });
})
