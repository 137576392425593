$(function() {
  $(document).ready(function(){
    let mthdElement = $("select#distribution_route_calc_delivery_date_mthd");

    let deliveryMthdHandler = function(){
      $("fieldset#delivery-week-rules-container").css('display', 'none');
      $("fieldset#delivery-fixed_duration-container").css('display', 'none');
      if(mthdElement.val() == 'week_rules'){$("fieldset#delivery-week-rules-container").css('display', 'block');}
      if(mthdElement.val() == 'fixed_duration'){$("fieldset#delivery-fixed_duration-container").css('display', 'block');}
    }

    deliveryMthdHandler();
    mthdElement.change(deliveryMthdHandler);
  })
})
