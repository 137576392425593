$(function () {
  const IsmapRendered = $("#routing-map").is(":visible");
  if (IsmapRendered) {
    fetchMapDetails().done((details) => {
      const points = details["points"];
      const route = details["route"];
      const startPoint = points["features"][0];
      const startPointCoords = startPoint["geometry"]["coordinates"];
      var map = L.map("routing-map").setView(
        [startPointCoords[1], startPointCoords[0]],
        18
      );
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
      }).addTo(map);
      L.geoJSON(route).addTo(map);
      L.geoJSON(points, {
        onEachFeature: function(feature,layer){
          layer.bindPopup('<div> <h3> Retailer ID: ' + feature.properties.retailer_id + '</h3> <h3>'+ feature.properties.description +'</h3></div>')
        },
        pointToLayer: function (_, latlng) {
          return L.circleMarker(latlng, {
            radius: 6,
            fillColor: "#ff7800",
            color: "#000",
            weight: 2,
            fillOpacity: 1,
            opacity: 1
          });
        },
      }).addTo(map);
    });
  }
});

function fetchMapDetails() {
  var planID = $(".row-id td").text();
  return $.ajax({
    type: "GET",
    url: `/admin/routing_plans/${planID}/routing_plan_details`,
    dataType: "json",
  });
}
