// app/javascript/packs/active_admin.js
$(document).ready(function () {
  function getPlural(word) {
    if (word == null) return '';
    const pluralRules = [
      {endsWith: ['s', 'sh', 'ch', 'x', 'z'], plural: `${word}es`},
      {endsWith: ['y'], plural: `${word.slice(0, -1)}ies`},
    ];

    for (const rule of pluralRules) {
      for (const ending of rule.endsWith) {
        if (word.endsWith(ending)) {
          return rule.plural;
        }
      }
    }

    // Default to adding "s" for unknown words
    return `${word}s`;
  }

  $('#feeable_type').on('change', function () {
    var feeableType = $(this).val();
    var feeableIdInput = $('#feeable_id');
    const entity = getPlural(convertToSnakeCase(feeableType.replace("CoreEngine::", "")));
    feeableIdInput.select2({
      ajax: {
        url: `/admin/${entity}/search`,
        dataType: 'json',
        delay: 250,
        data: function (params) {
          return {
            q: params.term // search term
          };
        },
        processResults: function (data) {
          console.log(data);
          return {
            results: data.map(function (item) {
              return {id: item.id, text: item.name};
            })
          };
        },
        cache: true
      }
    });
  });
});


function convertToSnakeCase(str) {
  return str
    .replace(/([a-z])([A-Z])/g, '$1_$2')  // Insert an underscore between lowercase and uppercase letters
    .replace(/([A-Z])([A-Z][a-z])/g, '$1_$2') // Insert an underscore between two uppercase letters followed by a lowercase letter
    .toLowerCase();  // Convert the entire string to lowercase
}
