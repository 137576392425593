
$(function() {
  $('.search_select_filter .select2-container').css('width', '100%');
});

$(function() {
  $('#order_distribution_route_id_input .select2-container, #order_distribution_route_salesman_id_input .select2-container').css('width', '50%');
});

$(function() {
  $('input.datepicker').attr('autocomplete', 'off');
  $('input[type="text"],textarea').attr('dir', 'auto');
  $('input[type="email"],input[type="tel"]').attr('dir', 'ltr');
});

$(function () {
  $('#dashboard-date-filter .daterange').on('apply.daterangepicker', function(ev, picker) {
    var fromDate = picker.startDate.format('YYYY-MM-DD')
    var toDate = picker.endDate.format('YYYY-MM-DD')
    var search_params = new URLSearchParams(location.search);
    search_params.set('from_date', fromDate);
    search_params.set('to_date', toDate);
    location.search = search_params.toString();
  });
});

$(function() {
  $('#select-supplier #supplier').on('select2:select', function (e) {
    var search_params = new URLSearchParams(location.search);
    search_params.set('supplier', e.params.data.id);
    location.search  = search_params.toString();
  });
});

$(function () {
  $("#select-supplier-offer #supplier_offer").on(
    "select2:select",
    function (e) {
      var search_params = new URLSearchParams(location.search);
      search_params.set("supplier_offer", e.params.data.id);
      location.search = search_params.toString();
    }
  );
});

$(function () {
  $("#select-month #month").on("select2:select", function (e) {
    var search_params = new URLSearchParams(location.search);
    search_params.set("month", e.params.data.id);
    location.search = search_params.toString();
  });
});

$(function() {
  $('#select-brand #brand').on('select2:select', function (e) {
    var search_params = new URLSearchParams(location.search);
    search_params.set('brand', e.params.data.id);
    location.search  = search_params.toString();
  });
});

$(function() {
  $('#select-category #category').on('select2:select', function (e) {
    var search_params = new URLSearchParams(location.search);
    search_params.set('category', e.params.data.id);
    location.search  = search_params.toString();
  });
});