$(function () {
  const IsmapRendered = $("#preview-routing-map").is(":visible");
  if (IsmapRendered) {
    var loader = document.getElementById("loader");
    loader.style.display = "block";

    fetchMapDetails()
      .done((details) => {
        loader.style.display = "none";
        // details shoud look like this { errors: [], routes: [{points: [], route: [], unassigned:[], summary: [] }] }
        // multiple routes for horeca causes.
        var tableContainer = document.getElementById("table-container");

        const { routes, polygon, errors } = details;
        if(routes.length !== 0){
          var table = buildTable()
          var tbody = document.createElement("tbody");
        }
        else{
          var h4 = document.createElement("h4");
          h4.appendChild(document.createTextNode("No routes are available. Please check tasks and/or retailers and try again."));
          tableContainer.appendChild(h4);
        }

        displayErrors(errors);
        const startPointCoords = polygon["geometry"]["coordinates"][0][0];
        var map = L.map("preview-routing-map").setView(
          [startPointCoords[1], startPointCoords[0]],
          14
        );
        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution:
            '&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
        }).addTo(map);

        L.geoJSON(polygon).addTo(map);

        routes.forEach((routeDetails, index) => {
          const { points, route, unassigned, summary, strategy } = routeDetails;
          L.geoJSON(route).addTo(map);
          L.geoJSON(points, {
            onEachFeature: function (feature, layer) {
              layer.bindPopup(
                "<div> <h4> Retailer ID: " +
                  feature.properties.retailer_id +
                  "</h4> <h4>" +
                  feature.properties.description +
                  "</h4></div>"
              );
            },
            pointToLayer: function (_, latlng) {
              return L.circleMarker(latlng, {
                radius: 6,
                fillColor: "#ff7800",
                color: "#000",
                weight: 2,
                fillOpacity: 1,
                opacity: 1,
              });
            },
          }).addTo(map);

          var tr = document.createElement("tr");
          var routeCell = document.createElement("td");
          routeCell.appendChild(
            document.createTextNode("Route " + (index + 1))
          );
          tr.appendChild(routeCell);

          var distanceCell = document.createElement("td");
          distanceCell.appendChild(
            document.createTextNode(summary["distance"] / 1000 + " km")
          );
          tr.appendChild(distanceCell);

          var RouteCountCell = document.createElement("td");
          RouteCountCell.appendChild(
            document.createTextNode(points["features"].length)
          );
          tr.appendChild(RouteCountCell);

          var unassignedCell = document.createElement("td");
          unassignedCell.appendChild(
            document.createTextNode(unassigned.length)
          );
          tr.appendChild(unassignedCell);

          var strategyCell = document.createElement("td");
          strategyCell.appendChild(
            document.createTextNode(strategy)
          );
          tr.appendChild(strategyCell);

          tbody.appendChild(tr);
        });

        table.appendChild(tbody);
        tableContainer.appendChild(table);
      })
      .fail((error) => {
        loader.style.display = "none";
        displayErrors(error.responseJSON.errors);
      });
  }
});

function fetchMapDetails() {
  var salesAreaId = $("#sales_area_id").val();
  return $.ajax({
    type: "GET",
    url: `/admin/sales_areas/${salesAreaId}/preview_plans`,
    dataType: "json",
  });
}

function buildTable() {
  // Create table and table elements
  var table = document.createElement("table");
  table.style.width = "100%";
  table.style.textAlign = "center";
  var thead = document.createElement("thead");
  var tbody = document.createElement("tbody");
  var headerRow = document.createElement("tr");
  // Add table headers
  var headers = ["Routes", "Distance", "Total Retailers", "Unassigned", "Best Strategy"];
  headers.forEach((header) => {
    var th = document.createElement("th");
    th.appendChild(document.createTextNode(header));
    th.style.textAlign = "center";
    headerRow.appendChild(th);
  });
  thead.appendChild(headerRow);
  table.appendChild(thead);

  return table;
}

function displayErrors(errors) {
  if (errors.length === 0) return;
  var errorContainer = document.getElementById("error-container");
  var table = document.createElement("table");
  table.style.width = "100%";
  table.style.textAlign = "left";

  var thead = document.createElement("thead");
  var headerRow = document.createElement("tr");
  var headers = ["Error Messages"];
  headers.forEach((header) => {
    var th = document.createElement("th");
    th.appendChild(document.createTextNode(header));
    th.style.margin = '5px';
    headerRow.appendChild(th);
  });
  thead.appendChild(headerRow);
  table.appendChild(thead);

  var tbody = document.createElement("tbody");
  errors.forEach((error) => {
    var tr = document.createElement("tr");

    var errorMessageCell = document.createElement("td");
    errorMessageCell.appendChild(document.createTextNode(error));
    tr.appendChild(errorMessageCell);
    tr.style.margin = '5px';

    tr.style.color = "red";

    tbody.appendChild(tr);
  });
  table.appendChild(tbody);

  errorContainer.appendChild(table);
}
