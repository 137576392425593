$(function () {
  $('.reason').on('change', function () {
    let comparisonValue = $("#reason_comparison_value").val();
    if ($(this).val() == comparisonValue) {
      $('.reason-details').show();
    }
    else{
      $('.reason-details').hide();
    }
  });
});

