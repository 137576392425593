$(function() {
  $(document).on('change', 'fieldset#rejection_reasons', function() {
    var error = $('select[name="installment_form_request_document[errors]"]').val()
    $.ajax({
      url: '/admin/installment_form_requests_documents/sub_errors',
      data: { reason: error },
      success: function(data) {
        var sub_errors_select = $('#reject_reason_sub_reason');
        sub_errors_select.empty();
        $.each(data, function(index, sub_error) {
          sub_errors_select.append($('<option>', {
            value: sub_error.id,
            text: sub_error.name
          }));
        });
      }
    });
  });
});
