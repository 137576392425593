var script = document.createElement('script');
script.async = true;
script.src = 'https://www.googletagmanager.com/gtag/js?id=' + 'G-2D10G4SVR3';
document.head.appendChild(script);

var codeScript = document.createElement('script');
codeScript.type = 'text/javascript';
codeScript.text = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-2D10G4SVR3');
`;
document.head.appendChild(codeScript);
