import 'select2';
import 'jquery-datetimepicker';
import  './vendor/jquery_palette_color_picker/palette-color-picker';

import intlTelInput from 'intl-tel-input';
window.intlTelInput = intlTelInput;

import JSONEditor from 'jsoneditor';
window.JSONEditor = JSONEditor;
import './config';
import './inputs/select2';
import './inputs/search-select';
import './inputs/nested-select';
import './inputs/tags';
import './inputs/selected-list';
import './inputs/date-time-picker';
import './inputs/color-picker';
import './inputs/tel';
import './inputs/jsoneditor';
import './addons/toggle_bool';
import './addons/interactive_select_tag';
