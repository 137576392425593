
import { Loader, LoaderOptions } from 'google-maps';
const options = {/* todo */ };
const apiKey = "AIzaSyBfwVy721hR-gTwm8EB528c490H7mUDPh0"
const loader = new Loader(apiKey, options);

$(() => {
  if($('#polygon-map').length) {

  // Define the LatLng coordinates for the default polygon's path.

  const defaultCoords = JSON.stringify([
    {lat: 30.034889890147216, lng: 31.217519760131847},
    {lat: 30.039249491458925, lng: 31.219302177429203},
    {lat: 30.04023207483109, lng: 31.214561462402344},
    {lat: 30.034519536566133, lng: 31.214446067810062}
  ]);

  const savedCoords = $('.polygon-input-coordinates-view-only td').html() || $('.polygon-input-coordinates').val();
  let currentCoords = savedCoords || defaultCoords
 
  try {
    currentCoords = JSON.parse(currentCoords)
  } catch(error) {
    currentCoords = JSON.parse(defaultCoords)
  }
  const polygonColor =  savedCoords ? "#ff0000" :"#333"
  loader.load().then(function (google) {
    const map = new google.maps.Map(document.getElementById('polygon-map'),{
      center: currentCoords[2],
      zoom: 15
    });

    const isViewOnly = $('tr').hasClass('polygon-input-coordinates-view-only'); 

    if(isViewOnly) {
      $('tr.polygon-input-coordinates-view-only').hide();
    }

    // Construct the polygon.
    const routePolygon = new google.maps.Polygon({
      paths: currentCoords,
      strokeColor:polygonColor,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: polygonColor,
      fillOpacity: 0.35,
      editable: isViewOnly ? false : true,
      draggable: isViewOnly ? false : true,
    });

    if(!currentCoords) {
      $('.polygon-input-coordinates').val(JSON.stringify(routePolygon.getPath().getArray()))
    }

    routePolygon.addListener("mouseup", () => {
      $('.polygon-input-coordinates').val(JSON.stringify(routePolygon.getPath().getArray()));
      routePolygon.setOptions({strokeColor: "#ff0000", fillColor: "#ff0000"})
    });
    routePolygon.setMap(map);
  })
  }
});
