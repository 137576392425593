$(function () {
  $('#product_supplier_search').select2({
    minimumInputLength: 2, // Minimum number of characters before the search is performed
    ajax: {
      url: '/admin/products/search', // Replace with the actual path to your search action
      dataType: 'json',
      delay: 250,
      data: function(params) {
        return {
          q: params.term
        };
      },
      processResults: function(data) {
        data = data.map(function (obj) {
          obj.id = obj.id;
          obj.text = obj.name;
          return obj;
        });
        return {
          results: data // Assuming your search action returns a JSON array of items with 'id' and 'text' attributes
        };
      },
      cache: true
    }
  }).on('select2:select', (e) => {
    $('.product_supplier_form_container').show();
    $('.add-new-product').hide()
  })
});
