$(document).ready(function() {
  var $supplierServiceInput = $('#supplier_service_transaction_supplier_service');
  var $supplierServiceIdInput = $('#supplier_service_transaction_supplier_service_id');

  if ($supplierServiceInput.length && $supplierServiceIdInput.length) {
    console.log('Initial value:', $supplierServiceInput.val());

    function fetchData(serviceType) {
      var hostname= window.location.hostname;

    var url = `https://${hostname}/admin/supplier_service_transactions/supplier_service_id`;  
      $.ajax({
        url: url,
        data: { service_type: serviceType },
        success: function(data) {
          console.log('Data received:', data);
          $supplierServiceIdInput.empty();
          // Append an empty option first
          $supplierServiceIdInput.append($('<option>', {
            value: '',
            text: ''
          }));
          $.each(data, function(index, option) {
            $supplierServiceIdInput.append($('<option>', {
              value: option.id,
              text: option.name
            }));
          });
        },
        error: function(jqXHR, textStatus, errorThrown) {
          console.error('Error fetching data:', textStatus, errorThrown);
        }
      });
    }

    // Event listener for change event
    $supplierServiceInput.on('change', function() {
      var serviceType = $supplierServiceInput.val();
     console.log('Selected service type:', serviceType);
      
      // Check if the service type is empty and clear the supplier service dropdown
      if (!serviceType) {
        $supplierServiceIdInput.empty();
        $supplierServiceIdInput.append($('<option>', {
          value: '',
          text: ''
        }));
      } else {
        fetchData(serviceType);
      }
    });

  } else {
     console.error('One or both elements not found.');
  }
});
