$(function () {
  $(document).on('click touchstart', '#sidebar', function (e) {
    var position = $(this).position();
    var width = $(this).width();
    var target = e.target;
    if ((e.pageX > position.left) && (target.tagName == 'DIV')) {
      // works only with RTL
      if ($(this).css('left') == '0px') {
        //close filters
        $(this).css('position', 'fixed');
        $(this).animate({
          left: "-=" + width
        }, 600, function () {
          $(this).removeAttr('style');
          animationFilterDone = true;
        });
      } else {
        //open filters
        $(this).animate({
          left: "+=" + width
        }, 600, function () {
          $(this).css('position', 'absolute');
          animationFilterDone = true;
        });
      }
    }
  });
});
