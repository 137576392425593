$(function () {

  $('[data-original-value]').change(function() {
    $(this).css("border", '1px solid #FFA000' )
    var refreshElement = $(this).prev('i');
    if (!refreshElement.length) {
      $(this).before('<i class="fas fa-reply"></i>');
      refreshElement = $(this).prev('i');
      refreshElement.css( 'cursor', 'pointer' );
      refreshElement.click(function() {
        var inputElement = $(this).next();
        $(this).remove();
        inputElement.val(inputElement.data('original-value'));
        inputElement.css('border', '1px solid #d3d3d3');
      })
    }
  });
})
