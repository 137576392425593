import { Loader } from "google-maps";

document.addEventListener("DOMContentLoaded", () => {
  const apiKey = "AIzaSyBfwVy721hR-gTwm8EB528c490H7mUDPh0";
  const loader = new Loader(apiKey);
  const mapId = "retailer-groups-map";
  const routePolygonColor = "#333";
  const groupPolygonColor = "#ff0000";
  const publishedGroupPolygonColor = "#008000";

  async function createMapWithPolygon(routeId, groupCoordinates = []) {
    const distributionRouteCoordinates = await getDistributionRouteCoordinates(routeId);
    loader.load().then((google) => {
      const map = new google.maps.Map(document.getElementById(mapId), {
        center: distributionRouteCoordinates[2],
        zoom: 15,
      });

      const routePolygon = new google.maps.Polygon({
        paths: distributionRouteCoordinates,
        strokeColor: routePolygonColor,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: routePolygonColor,
        fillOpacity: 0.35,
      });

      if(groupCoordinates.length === 0) {
        groupCoordinates = [distributionRouteCoordinates[0] ,distributionRouteCoordinates[1], distributionRouteCoordinates[2], distributionRouteCoordinates[3]]
      }
      const groupPolygon = new google.maps.Polygon({
        paths: groupCoordinates,
        strokeColor: groupPolygonColor,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: groupPolygonColor,
        fillOpacity: 0.35,
        editable: true,
        draggable: true,
      });

      routePolygon.setMap(map);
      createPublishedGroupsPolygon(map, routeId);
      groupPolygon.setMap(map);
      const groupPolygonInput = $(".polygon-input-coordinates");
      groupPolygonInput.val(JSON.stringify(groupPolygon.getPath().getArray()));

      groupPolygon.addListener("mouseup", () => {
        groupPolygonInput.val(JSON.stringify(groupPolygon.getPath().getArray()));
        groupPolygon.setOptions({
          strokeColor: "#ff0000",
          fillColor: "#ff0000",
        });
      });
    });
  }

  async function createPublishedGroupsPolygon(map = null, routeId) {
    const publishedGroupPolygons = await fetchPublishedGroupPolygons(routeId);
    if (!map || publishedGroupPolygons.length === 0) {
      return;
    }
    publishedGroupPolygons.forEach(polygon => {
      const coordinates = getCoordinates(polygon);
      const mapPolygon = new google.maps.Polygon({
        paths: coordinates,
        strokeColor: publishedGroupPolygonColor,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: publishedGroupPolygonColor,
        fillOpacity: 0.35
      });
      mapPolygon.setMap(map);
    });
  }

  async function fetchPublishedGroupPolygons(routeId) {
    if (!routeId) {
      return [];
    }
    return await $.getJSON(`/admin/retailer_groups/${routeId}/published_groups_polygon`);
  }

  function getCoordinates(polygon) {
    const coordinateString = polygon.replace("POLYGON Z ((", "").replace("))", "");
    const coordinatePairs = coordinateString.split(", ");
    const coordinates = coordinatePairs.map((pair) => {
    const lngLat = pair.split(" ");
        return { lng: parseFloat(lngLat[0]), lat: parseFloat(lngLat[1]) };
    });
    return coordinates;
  }

  async function getDistributionRouteCoordinates(routeId){
    const result = await $.getJSON(`/admin/retailer_groups/${routeId}/distribution_route_polygon`);
    return getCoordinates(result.polygon);
  }

  let routeId = $('.distribution-route-id-input').val();
  $("#retailer_group_distribution_route_id").on("change", function () {
    routeId = $("#retailer_group_distribution_route_id").val();
    if (routeId) {
      createMapWithPolygon(routeId);
    }
  });
  const savedCoords = $('.polygon-input-coordinates-view-only td').html() ||
                      $('.polygon-input-coordinates').val();
  if (savedCoords) {
    const groupCoordinates = JSON.parse(savedCoords);
    createMapWithPolygon(routeId, groupCoordinates);
  }
});
